import { createAction, props } from '@ngrx/store';
import { GthInvoiceModel } from '@sentinels/models';

export enum InvoicesActionTypes {
  LoadInvoicesByUserId = '[Invoices] Load Invoices By User Id',
  LoadInvoicesByUserIdSuccess = '[Invoices] Load Invoices By User Id Success',
  LoadInvoicesByUserIdError = '[Invoices] Load Invoices By User Id Error',
}

export const LoadInvoicesByUserId = createAction(
  InvoicesActionTypes.LoadInvoicesByUserId,
  props<{ userId: string }>(),
);

export const LoadInvoicesByUserIdSuccess = createAction(
  InvoicesActionTypes.LoadInvoicesByUserIdSuccess,
  props<{ invoices: GthInvoiceModel[] }>(),
);

export const LoadInvoicesByUserIdError = createAction(
  InvoicesActionTypes.LoadInvoicesByUserIdError,
  props<{ error: unknown }>(),
);
